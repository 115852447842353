<template>
  <v-row>
    <v-col class="d-flex justify-end">
      <v-dialog v-model="showForm" width="450" persistent>
        <v-card>
          <v-card-title class="text-h5 primary" v-if="showNewForm">
            Nuovo colore
          </v-card-title>
          <v-card-title class="text-h5 primary" v-if="showEditForm">
            Modifica colore
          </v-card-title>

          <v-card-text>
            <v-row>
              <!-- <v-col cols="12">
                <v-text-field label="Nome" v-model="form.name"></v-text-field>
              </v-col> -->

              <v-col cols="12">
                <v-text-field
                  label="Pantone"
                  v-model="form.pantone"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="C"
                  v-model="form.cmyk[0]"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="M"
                  v-model="form.cmyk[1]"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Y"
                  v-model="form.cmyk[2]"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="K"
                  v-model="form.cmyk[3]"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="R"
                  v-model="form.rgb[0]"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="G"
                  v-model="form.rgb[1]"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="B"
                  v-model="form.rgb[2]"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field label="HEX" v-model="form.hex"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field label="RAL" v-model="form.ral"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2 submit"
              @click="closeDialog"
              >Annulla</v-btn
            >
            <v-btn color="primary" @click="submit"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions } from "vuex";
import {
  CREATE_COLOR,
  UPDATE_COLOR,
} from "@/core/services/store/colors.module";

export default {
  name: "colorForm",

  props: {
    color: {
      type: Object,
      default: function () {
        return {};
      },
    },

    colorBlockId: {
      type: String,
    },

    showNewForm: {
      type: Boolean,
      default: false,
    },

    showEditForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        name: null,
        hex: null,
        pantone: null,
        cmyk: [0, 0, 0, 0],
        rgb: [0, 0, 0],
        ral: null,
      },

      isFormValid: false,
    };
  },

  watch: {
    showEditForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },

    showNewForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },
  },

  computed: {
    showForm: {
      get() {
        return this.showNewForm || this.showEditForm;
      },
      set() {},
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    closeDialog() {
      this.$emit("close");
    },

    onFileChange(file) {
      if (file != null) {
        this.imageUrl = URL.createObjectURL(file);
      } else {
        this.imageUrl = null;
      }

      this.form.image = file;
    },

    initForm() {
      if (this.color.id != null) {
        // this.form.name = this.color.name;
        this.form.hex = this.color.hex;
        this.form.pantone = this.color.pantone;
        this.form.cmyk = this.color.cmyk;
        this.form.rgb = this.color.rgb;
        this.form.ral = this.color.ral;
      } else {
        // this.form.name = null;
        this.form.hex = null;
        this.form.pantone = null;
        this.form.cmyk = [0, 0, 0, 0];
        this.form.rgb = [0, 0, 0];
        this.form.ral = null;
      }
    },

    submit() {
      this.validateForm();

      if (this.isFormValid) {
        let params = {
          // name: this.form.name,
          hex: this.form.hex,
          pantone: this.form.pantone,
          cmyk: this.form.cmyk,
          rgb: this.form.rgb,
          ral: this.form.ral,
        };

        if (this.showEditForm) {
          /// UPDATE

          this.$store
            .dispatch(UPDATE_COLOR, {
              id: this.color.id,
              params: params,
            })
            .then(() => {
              this.closeDialog();

              this.showSnack({
                text: "Colore aggiornato con successo.",
                color: "success",
                timeout: 3500,
              });

              this.$emit("change");
            });
        } else if (this.showNewForm) {
          /// CREATE
          params["color_block_id"] = this.colorBlockId;

          this.$store.dispatch(CREATE_COLOR, params).then(() => {
            this.closeDialog();

            this.showSnack({
              text: "Colore creato con successo.",
              color: "success",
              timeout: 3500,
            });
            this.$emit("change");
          });
        }
      }
    },

    validateForm() {
      this.isFormValid = true;
    },
  },
};
</script>
