<template>
    <div class="block-row mb-5">
        <!-- Header -->
        <v-row class="d-flex justify-end">
            <v-col cols="6">
                <h4>Immagine</h4>
            </v-col>

            <v-spacer></v-spacer>
            
            <v-btn-toggle dense style="background-color: transparent">
                <v-btn class="delete-btn" v-if="deletable" @click="confirmDelete">
                    <i class="flaticon2-rubbish-bin"></i>
                </v-btn>
            </v-btn-toggle>
        </v-row>

        <v-divider></v-divider>

        <v-row>
            <v-col cols="12">
                <img :src="imageBlock.content.image_url"
                    v-if="imageBlock.content.image_url" />
            </v-col>
        </v-row>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import {
    DELETE_TEXT_BLOCK,
} from "@/core/services/store/text_blocks.module";

export default {
    components: {
    },

    props: {
        imageBlock: {
            type: Object,
            default: function () {
                return {};
            },
        },

        deletable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
        };
    },

    computed: {
    },

    mounted() {
    },

    watch: {
    },

    methods: {
        ...mapActions("snackbar", ["showSnack"]),

        changed() {
            this.closeDialog();
            this.$emit("change");
        },

        closeDialog() {
            this.showNewForm = false;

            this.$emit("close");
        },

        confirmDelete() {
            confirm(
                "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
            ) && this.delete();
        },

        delete() {
            this.$store.dispatch(DELETE_TEXT_BLOCK, this.imageBlock.id).then(() => {
                this.showSnack({
                    text: "Blocco immagine eliminato con successo.",
                    color: "success",
                    timeout: 3500,
                });

                this.$emit("change");
            });
        },
    },
};
</script>
