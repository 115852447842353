<template>
  <div>
    <!-- Form edit GalleryBlock -->
    <GalleryBlockForm
      :galleryBlock="galleryBlock"
      :chapterId="chapterId"
      :showEditForm="showEditForm"
      @change="changed"
      @close="closeDialog"
    />

    <div class="block-row mb-5">
      <!-- Header -->
      <v-row class="d-flex justify-end">
        <v-col cols="6">
          <h4>Galleria immagini</h4>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn-toggle dense style="background-color: transparent">
          <v-btn class="new-btn" @click="openNewImageForm"
            ><i class="flaticon2-add-1"></i
          ></v-btn>
          <v-btn class="edit-btn" @click="openEditForm"
            ><i class="flaticon2-edit"></i
          ></v-btn>
          <v-btn class="delete-btn" @click="confirmDelete" v-if="deletable">
            <i class="flaticon2-rubbish-bin"></i>
          </v-btn>
        </v-btn-toggle>
      </v-row>

      <v-divider></v-divider>

      <!-- Dettagli galleria immagini -->
      <v-row>
        <v-col cols="4">
          <label>Numero colonne:</label>
          <span class="ml-2">{{ this.galleryBlock.content.columns }}</span>
        </v-col>

        <v-col cols="4">
          <label>Formato</label>
          <span class="ml-2">{{ this.formatString }}</span>
        </v-col>

        <v-col cols="4">
          <label>Versione mobile</label>
          <span class="ml-2">{{ this.mobileTypeString }}</span>
        </v-col>
      </v-row>

      <!-- Immagini -->
      <GalleryImage
        :columns="12 / this.galleryBlock.content.columns"
        :images="this.galleryBlock.content.gallery_images"
        :imageFormat="this.galleryBlock.content.format"
        :galleryBlockId="this.galleryBlock.content.id"
        @change="changed"
        @close="closeDialog"
      />

      <GalleryImageForm
        :galleryBlockId="this.galleryBlock.content.id"
        :showNewForm="showNewImageForm"
        @change="changed"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { DELETE_GALLERY_BLOCK } from "@/core/services/store/gallery_blocks.module";
import GalleryBlockForm from "@/view/components/Block/Forms/GalleryBlockForm.vue";
import GalleryImage from "@/view/components/Block/GalleryImage.vue";
import GalleryImageForm from "@/view/components/Block/Forms/GalleryImageForm.vue";

export default {
  name: "GalleryBlock",

  components: {
    GalleryBlockForm,
    GalleryImage,
    GalleryImageForm,
  },

  props: {
    galleryBlock: {
      type: Object,
      default: function () {
        return {};
      },
    },
    chapterId: {
      type: String,
    },

    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      columns: [1, 2, 3, 4],
      format: ["16:9", "1:1"],
      format_values: ["16_9", "1_1"],
      isFormValid: false,
      mobile_type: ["Normale", "1 Colonna", "Slider", "Carattere"],
      mobile_type_values: ["normal", "one_column", "slider", "fonts"],

      showEditForm: false,

      showNewImageForm: false,
    };
  },

  computed: {
    mobileTypeString() {
      if (this.galleryBlock.content.mobile_type == null) {
        return "";
      }

      let index = this.mobile_type_values.indexOf(
        this.galleryBlock.content.mobile_type
      );
      return index > -1 ? this.mobile_type[index] : "";
    },

    formatString: function () {
      if (this.galleryBlock.content.format == null) {
        return "";
      }

      let index = this.format_values.indexOf(this.galleryBlock.content.format);
      return index > -1 ? this.format[index] : "";
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.closeDialog();
      this.$emit("change");
    },

    closeDialog() {
      this.showEditForm = false;
      this.showNewImageForm = false;

      this.$emit("close");
    },

    confirmDelete() {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete();
    },

    delete() {
      this.$store
        .dispatch(DELETE_GALLERY_BLOCK, this.galleryBlock.id)
        .then(() => {
          this.showSnack({
            text: "Galleria eliminata con successo.",
            color: "success",
            timeout: 3500,
          });

          this.changed();
          this.cleanupParams();
        });
    },

    openEditForm() {
      this.showEditForm = true;
    },

    openNewImageForm() {
      this.showNewImageForm = true;
    },
  },
};
</script>