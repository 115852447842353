<template>
  <v-row>
    <v-col class="d-flex justify-end">
      <v-dialog v-model="showForm" width="450" persistent>
        <v-card>
          <v-card-title class="text-h5 primary" v-if="showNewForm">
            Nuovo Power Brand
          </v-card-title>
          <v-card-title class="text-h5 primary" v-if="showEditForm">
            Modifica Power Brand
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <img
                  :src="img_logo"
                  style="width: 50%"
                  v-if="img_logo != null"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  accept="image/*"
                  label="Logo"
                  v-model="form.img_logo"
                  @change="(file) => onFileChange(file, 'img_logo')"
                ></v-file-input>
              </v-col>

              <v-col cols="12">
                <img
                  :src="img_logo_hover"
                  style="width: 50%"
                  v-if="img_logo_hover != null"
                />
              </v-col>

              <v-col cols="12">
                <v-file-input
                  accept="image/*"
                  label="Logo hover"
                  v-model="form.img_logo_hover"
                  @change="(file) => onFileChange(file, 'img_logo_hover')"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <img
                  :src="img_back"
                  style="width: 50%"
                  v-if="img_back != null"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  accept="image/*"
                  label="Immagine posteriore"
                  v-model="form.img_back"
                  @change="(file) => onFileChange(file, 'img_back')"
                ></v-file-input>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Titolo"
                  v-model="form.title"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Descrizione"
                  v-model="form.description"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Didascalia"
                  v-model="form.caption"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Link"
                  v-model="form.link_url"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="secondary"
              dark
              class="mr-2 submit"
              @click="closeDialog"
            >
              Annulla
            </v-btn>
            <v-btn color="primary" @click="submit"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import {
  CREATE_POWER_BRAND_ITEM,
  UPDATE_POWER_BRAND_ITEM,
} from "@/core/services/store/power_brand_items.module";

export default {
  name: "PowerBrandItemForm",

  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },

    blockId: {
      type: String,
    },

    showNewForm: {
      type: Boolean,
      default: false,
    },

    showEditForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        title: null,
        description: null,
        caption: null,
        link_url: null,
        img_logo: null,
        img_logo_hover: null,
        img_back: null,
      },

      img_logo: null,
      img_logo_hover: null,
      img_back: null,
      isFormValid: false,
    };
  },

  watch: {
    showEditForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },

    showNewForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },
  },

  computed: {
    showForm: {
      get() {
        return this.showNewForm || this.showEditForm;
      },
      set() {},
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    closeDialog() {
      this.$emit("close");
    },

    onFileChange(file, field) {
      if (file != null) {
        this.$set(this, field, URL.createObjectURL(file));
      } else {
        this.$set(this, field, null);
      }

      this.form[field] = file;
    },

    initForm() {
      if (this.showNewForm) {
        this.img_logo = null;
        this.img_logo_hover = null;
        this.img_back = null;
      }

      if (this.item != null) {
        this.form.title = this.item.title;
        this.form.description = this.item.description;
        this.form.caption = this.item.caption;
        this.form.link_url = this.item.link_url;
        this.form.img_logo = null;
        this.form.img_logo_hover = null;
        this.form.img_back = null;
        this.img_logo = this.item.img_logo_url;
        this.img_logo_hover = this.item.img_logo_hover_url;
        this.img_back = this.item.img_back_url;
      } else {
        this.form.title = null;
        this.form.description = null;
        this.form.quote = null;
        this.form.caption = null;
        this.form.img_logo = null;
        this.form.img_logo_hover = null;
        this.form.img_back = null;
        this.img_logo = null;
        this.img_logo_hover = null;
        this.img_back = null;
      }
    },

    submit() {
      this.validateForm();

      if (this.isFormValid) {
        let params = {
          title: this.form.title,
          description: this.form.description,
          caption: this.form.caption,
          link_url: this.form.link_url,
        };

        if (this.form.img_logo != null) {
          params["img_logo"] = this.form.img_logo;
        }
        if (this.form.img_logo_hover != null) {
          params["img_logo_hover"] = this.form.img_logo_hover;
        }
        if (this.form.img_back != null) {
          params["img_back"] = this.form.img_back;
        }

        if (this.showEditForm) {
          /// UPDATE
          let formData = this.jsonToFormData({ power_brand: params });

          this.$store
            .dispatch(UPDATE_POWER_BRAND_ITEM, {
              id: this.item.id,
              params: formData,
            })
            .then(() => {
              this.closeDialog();

              this.showSnack({
                text: "Power brand aggiornato con successo.",
                color: "success",
                timeout: 3500,
              });

              this.$emit("change");
            });
        } else if (this.showNewForm) {
          /// CREATE
          params["power_brand_block_id"] = this.blockId;

          let formData = this.jsonToFormData({ power_brand: params });

          this.$store.dispatch(CREATE_POWER_BRAND_ITEM, formData).then(() => {
            this.closeDialog();

            this.showSnack({
              text: "Power brand creato con successo.",
              color: "success",
              timeout: 3500,
            });
            this.$emit("change");
          });
        }
      }
    },

    validateForm() {
      this.isFormValid = this.img_logo != null && this.img_back != null;
    },
  },
};
</script>