<template>
  <v-row>
    <v-col class="d-flex justify-end">
      <v-dialog v-model="showForm" width="450" persistent>
        <v-card>
          <v-card-title class="text-h5 primary" v-if="showNewForm">
            Nuova immagine
          </v-card-title>
          <v-card-title class="text-h5 primary" v-if="showEditForm">
            Modifica immagine
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <img
                  :src="imageUrl"
                  style="width: 100%"
                  v-if="imageUrl != null"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  accept="image/*"
                  label="Immagine"
                  v-model="form.image"
                  @change="onFileChange"
                ></v-file-input>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Titolo"
                  v-model="form.title"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Didascalia"
                  v-model="form.caption"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2 submit"
              @click="closeDialog"
              >Annulla</v-btn
            >
            <v-btn color="primary" @click="submit"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions } from "vuex";
import {
  CREATE_SWIPER_IMAGE,
  UPDATE_SWIPER_IMAGE,
} from "@/core/services/store/swiper_images.module";

export default {
  name: "SwiperImageForm",

  props: {
    swiperImage: {
      type: Object,
      default: function () {
        return {};
      },
    },

    swiperBlockId: {
      type: String,
    },

    showNewForm: {
      type: Boolean,
      default: false,
    },

    showEditForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        caption: null,
        position: null,
        title: null,
        image: null,
      },

      imageUrl: null,
      isFormValid: false,
    };
  },

  watch: {
    showEditForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },

    showNewForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },
  },

  computed: {
    showForm: {
      get() {
        return this.showNewForm || this.showEditForm;
      },
      set() {},
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    closeDialog() {
      this.$emit("close");
    },

    onFileChange(file) {
      if (file != null) {
        this.imageUrl = URL.createObjectURL(file);
      } else {
        this.imageUrl = null;
      }

      this.form.image = file;
    },

    initForm() {
      if (this.showNewForm) {
        this.imageUrl = null;
      }

      if (this.swiperImage != null) {
        this.form.caption = this.swiperImage.caption;

        this.form.title = this.swiperImage.title;
        this.form.image = null;
        this.imageUrl = this.swiperImage.image_url;
      } else {
        this.form.caption = null;

        this.form.title = null;
        this.form.image = null;
        this.imageUrl = null;
      }
    },

    submit() {
      this.validateForm();

      if (this.isFormValid) {
        let params = {
          caption: this.form.caption,
          title: this.form.title,
        };
        if (this.form.image != null) {
          params["attachment"] = this.form.image;
        }

        if (this.showEditForm) {
          /// UPDATE
          let formData = this.jsonToFormData({ swiper_image: params });

          this.$store
            .dispatch(UPDATE_SWIPER_IMAGE, {
              id: this.swiperImage.id,
              params: formData,
            })
            .then(() => {
              this.closeDialog();

              this.showSnack({
                text: "Immagine aggiornata con successo.",
                color: "success",
                timeout: 3500,
              });

              this.$emit("change");
            });
        } else if (this.showNewForm) {
          /// CREATE
          params["swiper_block_id"] = this.swiperBlockId;

          let formData = this.jsonToFormData({ swiper_image: params });

          this.$store.dispatch(CREATE_SWIPER_IMAGE, formData).then(() => {
            this.closeDialog();

            this.showSnack({
              text: "Immagine creata con successo.",
              color: "success",
              timeout: 3500,
            });
            this.$emit("change");
          });
        }
      }
    },

    validateForm() {
      this.isFormValid = this.imageUrl != null;
    },
  },
};
</script>
