<template>
    <div class="block-row mb-5">
        <!-- Header -->
        <v-row class="d-flex justify-end">
            <v-col cols="6">
                <h4>Asset</h4>
            </v-col>

            <v-spacer></v-spacer>
            
            <v-btn-toggle dense style="background-color: transparent">
                <v-btn class="new-btn" @click="openNewForm">
                    <i class="flaticon2-add-1"></i>
                </v-btn>
                <v-btn class="delete-btn" v-if="deletable" @click="confirmDelete">
                    <i class="flaticon2-rubbish-bin"></i>
                </v-btn>
            </v-btn-toggle>
        </v-row>

        <v-divider></v-divider>

        <draggable
            v-model="sortableItems"
            class="v-list v-sheet theme--light v-list--subheader"
            :group="assetBlock.content.id"
            @start="drag = true"
            @end="drag = false">
            <v-list-item
                v-for="item in sortedItems"
                :key="item.id">
                <v-list-item-avatar>
                    <v-icon
                        class="blue"
                        dark
                        v-text="'mdi-clipboard-text'"></v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        <a :href="item.attachment" target="_blank">
                            {{ item.link_text }}
                        </a>
                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn
                        dark
                        icon
                        @click="confirmItemDelete(item)">
                        <v-icon color="grey lighten-1">mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </draggable>

        <!-- Form AssetItem -->
        <AssetItemForm
            :assetBlockId="assetBlock.content.id"
            :showNewForm="showNewForm"
            @change="changed"
            @close="closeDialog" />
    </div>
</template>


<script>
import { mapActions } from "vuex";
import {
    DELETE_TEXT_BLOCK,
} from "@/core/services/store/text_blocks.module";
import {
    DELETE_ASSET_ITEM,
    SORT_ASSET_ITEMS,
} from "@/core/services/store/asset_items.module";
import draggable from "vuedraggable";
import AssetItemForm from "@/view/components/Block/Forms/AssetItemForm.vue";

export default {
    components: {
//        AssetItem,
        AssetItemForm,
        draggable,
    },

    props: {
        assetBlock: {
            type: Object,
            default: function () {
                return {};
            },
        },

        deletable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            showNewForm: false,
            drag: false,
            sortedItems: [],
        };
    },

    computed: {
        sortableItems: {
            get() {
                return (this.assetBlock.content.assets || []).sort((a, b) => {
                    if (a.position < b.position) return -1;
                    if (b.position < a.position) return 1;
                    return 0;
                });
            },

            set(value) {
                this.sortedItems = value;

                let asset_ids = value.map((item) => {
                    return item.id;
                });

                let params = {
                    asset_ids: asset_ids,
                };

                this.$store.dispatch(SORT_ASSET_ITEMS, params).then(() => {
                    this.$emit("change");
                });
            },
        },
    },

    mounted() {
        this.sortedItems = this.assetBlock.content.assets;
    },

    watch: {
        assetBlock: {
            deep: true,
            immediate: true,
            handler() {
                this.sortedItems = this.assetBlock.content.assets;
            },
        },
    },

    methods: {
        ...mapActions("snackbar", ["showSnack"]),

        changed() {
            this.closeDialog();
            this.$emit("change");
        },

        closeDialog() {
            this.showNewForm = false;

            this.$emit("close");
        },

        confirmDelete() {
            confirm(
                "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
            ) && this.delete();
        },

        delete() {
            this.$store.dispatch(DELETE_TEXT_BLOCK, this.assetBlock.id).then(() => {
                this.showSnack({
                    text: "Blocco asset eliminato con successo.",
                    color: "success",
                    timeout: 3500,
                });

                this.$emit("change");
            });
        },

        openNewForm() {
            this.showNewForm = true;
        },

        confirmItemDelete(item) {
            confirm(
                "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
            ) && this.deleteItem(item);
        },

        deleteItem(item) {
            this.$store.dispatch(DELETE_ASSET_ITEM, item.id).then(() => {
                this.showSnack({
                    text: "Asset eliminato con successo.",
                    color: "success",
                    timeout: 3500,
                });

                this.$emit("change");
            });
        },
    },
};
</script>
