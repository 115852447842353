<template>
  <v-row>
    <v-col class="d-flex justify-end">
      <v-dialog v-model="showEditForm" width="450" persistent>
        <v-card>
          <v-card-title class="text-h5 primary" v-if="showEditForm">
            Modifica range
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-file-input
                  accept="image/*"
                  label="Immagine sopra"
                  v-model="form.sourceAbove"
                  @change="onFileChangeAbove"
                ></v-file-input>
              </v-col>

              <v-col cols="6">
                <img
                  :src="sourceAboveUrl"
                  style="width: 100%"
                  v-if="sourceAboveUrl != null"
                />
              </v-col>

              <v-col cols="6">
                <v-file-input
                  accept="image/*"
                  label="Immagine sotto"
                  v-model="form.sourceBelow"
                  @change="onFileChangeBelow"
                ></v-file-input>
              </v-col>

              <v-col cols="6">
                <img
                  :src="sourceBelowUrl"
                  style="width: 100%"
                  v-if="sourceAboveUrl != null"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Soglia"
                  v-model="form.threshold"
                  :max="100"
                  :min="0"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Didascalia"
                  v-model="form.caption"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2 submit"
              @click="closeDialog"
              >Annulla</v-btn
            >
            <v-btn color="primary" @click="submit"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions } from "vuex";
import { UPDATE_RANGE } from "@/core/services/store/range.module";

export default {
  name: "RangeForm",

  props: {
    range: {
      type: Object,
      default: function () {
        return {};
      },
    },

    galleryBlockId: {
      type: String,
    },

    showEditForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        caption: null,
        sourceAbove: null,
        sourceBelow: null,
        threshold: 0,
      },

      sourceAboveUrl: null,
      sourceBelowUrl: null,
      isFormValid: false,
    };
  },

  watch: {
    showEditForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },

    showNewForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    closeDialog() {
      this.$emit("close");
    },

    onFileChangeAbove(file) {
      if (file != null) {
        this.sourceAboveUrl = URL.createObjectURL(file);
      } else {
        this.sourceAboveUrl = null;
      }

      this.form.sourceAbove = file;
    },

    onFileChangeBelow(file) {
      if (file != null) {
        this.sourceBelowUrl = URL.createObjectURL(file);
      } else {
        this.sourceBelowUrl = null;
      }

      this.form.sourceBelow = file;
    },

    initForm() {
      this.form.sourceAbove = null;
      this.form.sourceBelow = null;

      if (this.range != null) {
        this.form.caption = this.range.caption;
        this.form.threshold = this.range.threshold;
        this.sourceAboveUrl = this.range.source_above_url;
        this.sourceBelowUrl = this.range.source_below_url;
      }
    },

    submit() {
      this.validateForm();

      if (this.isFormValid) {
        let params = {
          caption: this.form.caption,
          threshold: this.form.threshold,
        };
        if (this.form.sourceAbove != null) {
          params["source_above"] = this.form.sourceAbove;
        }
        if (this.form.sourceBelow != null) {
          params["source_below"] = this.form.sourceBelow;
        }

        if (this.showEditForm) {
          /// UPDATE
          let formData = this.jsonToFormData({ range_item: params });

          this.$store
            .dispatch(UPDATE_RANGE, {
              id: this.range.id,
              params: formData,
            })
            .then(() => {
              this.closeDialog();
              this.form.sourceAbove = null;
              this.form.sourceBelow = null;

              this.showSnack({
                text: "Range aggiornato con successo.",
                color: "success",
                timeout: 3500,
              });

              this.$emit("change");
            });
        }
      }
    },

    validateForm() {
      this.isFormValid =
        this.sourceAboveUrl != null && this.sourceBelowUrl != null;
    },
  },
};
</script>
