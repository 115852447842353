<template>
  <div>
    <div class="block-row mb-5">
      <!-- Header -->
      <v-row class="d-flex justify-end">
        <v-col cols="6">
          <h4>Colori</h4>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn-toggle dense style="background-color: transparent">
          <v-btn class="new-btn" @click="openNewColorForm"
            ><i class="flaticon2-add-1"></i
          ></v-btn>

          <v-btn class="delete-btn" @click="confirmDelete"
            v-if="deletablòe">
            <i class="flaticon2-rubbish-bin"></i>
          </v-btn>
        </v-btn-toggle>
      </v-row>

      <v-divider></v-divider>

      <Color
        :colors="this.colorBlock.content.colors"
        :colorBlockId="this.colorBlock.content.id"
        @change="changed"
        @close="closeDialog"
      />

      <ColorForm
        :colorBlockId="this.colorBlock.content.id"
        :showNewForm="showNewColorForm"
        @change="changed"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { DELETE_COLOR_BLOCK } from "@/core/services/store/color_blocks.module";

import Color from "@/view/components/Block/Color.vue";
import ColorForm from "@/view/components/Block/Forms/ColorForm.vue";

export default {
  name: "ColorBlock",

  components: {
    Color,
    ColorForm,
  },

  props: {
    colorBlock: {
      type: Object,
      default: function () {
        return {};
      },
    },
    chapterId: {
      type: String,
    },
    
    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showNewColorForm: false,
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.closeDialog();
      this.$emit("change");
    },

    closeDialog() {
      this.showNewColorForm = false;

      this.$emit("close");
    },

    confirmDelete() {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete();
    },

    delete() {
      this.$store.dispatch(DELETE_COLOR_BLOCK, this.colorBlock.id).then(() => {
        this.showSnack({
          text: "Blocco colori eliminato con successo.",
          color: "success",
          timeout: 3500,
        });

        this.changed();
      });
    },

    openEditForm() {
      this.showEditForm = true;
    },

    openNewColorForm() {
      this.showNewColorForm = true;
    },
  },
};
</script>