<template>
    <v-row>
        <v-col class="d-flex justify-end">
            <v-dialog v-model="showForm" width="450" persistent>
                <v-card>
                    <v-card-title class="text-h5 primary" v-if="showNewForm">
                        Nuova immagine
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <img
                                    :src="attachment"
                                    style="width: 50%"
                                    v-if="attachment != null" />
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    accept="image/*"
                                    label="Attachment"
                                    v-model="form.attachment"
                                    @change="(file) => onFileChange(file, 'attachment')"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="secondary"
                            dark
                            class="mr-2 submit"
                            @click="closeDialog">
                            Annulla
                        </v-btn>
                        <v-btn color="primary" @click="submit"> Salva </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
import {
    CREATE_IMAGE_BLOCK,
} from "@/core/services/store/image_blocks.module";

export default {
    name: "ImageBlockForm",

    props: {
        imageBlock: {
            type: Object,
            default: function () {
                return {};
            },
        },

        chapterId: {
            type: String,
        },

        showNewForm: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            form: {
                blockable_type: "ImageBlock",
                chapter_id: this.chapterId,
                attachment: null,
            },

            attachment: null,
            isFormValid: false,
        };
    },

    watch: {
        showNewForm(newValue) {
            if (newValue) {
                this.initForm();
            }
        },
    },

    computed: {
        showForm: {
            get() {
                return this.showNewForm;
            },
            set() {},
        },
    },

    methods: {
        ...mapActions("snackbar", ["showSnack"]),

        closeDialog() {
            this.$emit("close");
        },

        onFileChange(file, field) {
            if (file != null) {
                this.$set(this, field, URL.createObjectURL(file));
            } else {
                this.$set(this, field, null);
            }

            this.form[field] = file;
        },

        initForm() {
            if (this.showNewForm) {
                this.attachment = null;
            }

            if (this.imageBlock != null) {
                this.form.attachment = null;
                this.attachment = this.imageBlock.attachment;
            } else {
                this.form.attachment = null;
                this.attachment = null;
            }
        },

        submit() {
            this.validateForm();

            if (this.isFormValid) {
                let params = {
                    blockable_type: "ImageBlock",
                };
                if (this.form.attachment != null) {
                    params["attachment"] = this.form.attachment;
                }

                if (this.showNewForm) {
                    /// CREATE
                    params["chapter_id"] = this.chapterId;

                    let formData = this.jsonToFormData({ block: params });

                    this.$store
                        .dispatch(CREATE_IMAGE_BLOCK, formData)
                        .then(() => {
                            this.closeDialog();

                            this.showSnack({
                                text: "Immagine creata con successo.",
                                color: "success",
                                timeout: 3500,
                            });
                            
                            this.$emit("change");
                        });
                }
            }
        },

        validateForm() {
            this.isFormValid = this.attachment != null;
        },
    },
};
</script>