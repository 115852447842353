<template>
  <div class="block-row mb-5">
    <!-- Header -->
    <v-row class="d-flex justify-end">
      <v-col cols="6">
        <h4>Power Brand</h4>
      </v-col>

      <v-spacer></v-spacer>

      <v-btn-toggle dense style="background-color: transparent">
        <v-btn class="new-btn" @click="openNewForm">
          <i class="flaticon2-add-1"></i>
        </v-btn>
        <v-btn class="delete-btn" v-if="deletable" @click="confirmDelete">
          <i class="flaticon2-rubbish-bin"></i>
        </v-btn>
      </v-btn-toggle>
    </v-row>

    <v-divider></v-divider>

    <!-- Items -->
    <PowerBrandItem
      :items="block.content.power_brands"
      :blockId="block.content.id"
      @change="changed"
      @close="closeDialog"
    />

    <!-- Form edit PowerBrandItems -->
    <PowerBrandItemForm
      :blockId="block.content.id"
      :showNewForm="showNewForm"
      @change="changed"
      @close="closeDialog"
    />
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { DELETE_TEXT_BLOCK } from "@/core/services/store/text_blocks.module";
import PowerBrandItem from "@/view/components/Block/PowerBrandItem.vue";
import PowerBrandItemForm from "@/view/components/Block/Forms/PowerBrandItemForm.vue";

export default {
  components: {
    PowerBrandItem,
    PowerBrandItemForm,
  },

  props: {
    block: {
      type: Object,
      default: function () {
        return {};
      },
    },

    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showNewForm: false,
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.$emit("change");
      this.closeDialog();
    },

    closeDialog() {
      this.showNewForm = false;

      this.$emit("close");
    },

    confirmDelete() {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete();
    },

    delete() {
      this.$store.dispatch(DELETE_TEXT_BLOCK, this.cardBlock.id).then(() => {
        this.showSnack({
          text: "Blocco power brand eliminato con successo.",
          color: "success",
          timeout: 3500,
        });

        this.$emit("change");
      });
    },

    openNewForm() {
      this.showNewForm = true;
    },
  },
};
</script>
