<template>
  <v-row>
    <v-col class="d-flex justify-end">
      <v-dialog v-model="showForm" width="450" ref="dialog" persistent>
        <v-card>
          <v-card-title class="text-h5 primary" v-if="showNewForm">
            Nuova galleria immagini
          </v-card-title>
          <v-card-title class="text-h5 primary" v-if="showEditForm">
            Modifica galleria immagini
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.columns"
                  :items="columns"
                  label="Colonne *"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.format"
                  :items="format"
                  label="Formato immagine *"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.mobile_type"
                  :items="mobile_type"
                  label="Tipo di blocco mobile *"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2 submit"
              @click="closeDialog"
              >Annulla</v-btn
            >
            <v-btn color="primary" @click="submit"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions } from "vuex";
import {
  CREATE_GALLERY_BLOCK,
  UPDATE_GALLERY_BLOCK,
} from "@/core/services/store/gallery_blocks.module";

export default {
  name: "GalleryBlockForm",

  props: {
    galleryBlock: {
      type: Object,
      default: function () {
        return {};
      },
    },

    chapterId: {
      type: String,
    },

    showNewForm: {
      type: Boolean,
      default: false,
    },

    showEditForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        columns: null,
        format: null,
        mobile_type: null,
      },
      params: {
        block: {
          blockable_type: "GalleryBlock",
          chapter_id: this.chapterId,
        },
      },

      columns: [1, 2, 3, 4],
      format: ["16:9", "1:1"],
      format_values: ["16_9", "1_1"],
      isFormValid: false,
      mobile_type: ["Normale", "1 Colonna", "Slider", "Carattere"],
      mobile_type_values: ["normal", "one_column", "slider", "fonts"],
    };
  },

  watch: {
    showEditForm(newValue) {
      if (newValue) {
        this.openEditForm();
      }
    },
  },

  computed: {
    showForm: {
      get() {
        return this.showNewForm || this.showEditForm;
      },
      set() {},
    },

    mobileTypeString() {
      if (this.galleryBlock == null) return "";
      if (this.galleryBlock.content.mobile_type == null) {
        return "";
      }

      let index = this.mobile_type_values.indexOf(
        this.galleryBlock.content.mobile_type
      );
      return index > -1 ? this.mobile_type[index] : "";
    },

    formatString: function () {
      if (this.galleryBlock == null) return "";
      if (this.galleryBlock.content.format == null) {
        return "";
      }

      let index = this.format_values.indexOf(this.galleryBlock.content.format);
      return index > -1 ? this.format[index] : "";
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    closeDialog() {
      this.$emit("close");
    },

    openEditForm() {
      this.cleanupParams();

      this.form.columns = this.galleryBlock.content.columns;
      this.form.format = this.formatString;
      this.form.mobile_type = this.mobileTypeString;
    },

    submit() {
      this.validateForm();

      if (this.isFormValid) {
        if (this.showEditForm) {
          /// UPDATE
          this.$store
            .dispatch(UPDATE_GALLERY_BLOCK, {
              id: this.galleryBlock.id,
              params: this.params,
            })
            .then(() => {
              this.closeDialog();
              this.showSnack({
                text: "Galleria aggiornata con successo.",
                color: "success",
                timeout: 3500,
              });
              this.$emit("change");
            });
        } else if (this.showNewForm) {
          /// CREATE
          this.$store.dispatch(CREATE_GALLERY_BLOCK, this.params).then(() => {
            this.closeDialog();
            this.cleanupParams();

            this.showSnack({
              text: "Galleria creata con successo.",
              color: "success",
              timeout: 3500,
            });
            this.$emit("change");
          });
        }
      }
    },

    validateForm() {
      let formatIndex =
        this.form.format != null ? this.format.indexOf(this.form.format) : -1;
      let mobileTypesIndex =
        this.form.mobile_type != null
          ? this.mobile_type.indexOf(this.form.mobile_type)
          : -1;

      if (
        formatIndex > -1 &&
        mobileTypesIndex > -1 &&
        this.form.columns != null
      ) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }

      this.params["block"]["columns"] = this.form.columns;
      this.params["block"]["format"] = this.format_values[formatIndex];
      this.params["block"]["mobile_type"] =
        this.mobile_type_values[mobileTypesIndex];
    },

    cleanupParams() {
      this.form.columns = null;
      this.form.mobile_type = null;
      this.form.format = null;
      this.params["block"]["columns"] = null;
      this.params["block"]["format"] = null;
      this.params["block"]["mobile_type"] = null;
    },
  },
};
</script>
