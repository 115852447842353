<template>
  <div>
    <draggable
      v-model="sortableItems"
      class="product-sorter-container row d-flex justify-start"
      :group="productBlockId"
      @start="drag = true"
      @end="drag = false"
    >
      <v-col
        class="product-container"
        v-for="item in sortedProducts"
        :cols="6"
        :key="item.id"
      >
        <!-- Header -->
        <v-btn-toggle
          dense
          style="background-color: transparent"
          class="d-flex justify-end"
        >
          <v-btn class="edit-btn" @click="openEditForm(item)"
            ><i class="flaticon2-edit"></i
          ></v-btn>
          <v-btn class="delete-btn" @click="confirmDelete(item)"
            v-if="deletable">
            <i class="flaticon2-rubbish-bin"></i>
          </v-btn>
        </v-btn-toggle>

        <!-- Dettagli immagine -->
        <img :src="item.image_url" class="product-image" />

        <div class="mb-5"></div>
        <div v-if="item.title != null && item.title != ''">
          <label class="mr-2">Titolo</label><span>{{ item.title }}</span>
        </div>
        <div v-if="item.caption != null && item.caption != ''">
          <label class="mr-2">Didascalia</label><span>{{ item.caption }}</span>
        </div>
      </v-col>
    </draggable>

    <!-- Form edit Product -->
    <ProductForm
      :product="product"
      :showEditForm="showEditForm"
      @change="changed"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  DELETE_PRODUCT,
  SORT_PRODUCT,
} from "@/core/services/store/products.module";
import ProductForm from "@/view/components/Block/Forms/ProductForm.vue";
import draggable from "vuedraggable";

export default {
  name: "Product",

  components: {
    ProductForm,
    draggable,
  },

  props: {
    products: {
      type: Array,
      default: function () {
        return [];
      },
    },

    productBlockId: {
      type: String,
    },
    
    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      product: null,
      showEditForm: false,
      drag: false,
      sortedProducts: [],
    };
  },

  computed: {
    sortableItems: {
      get() {
        return (this.products || []).sort((a, b) => {
          if (a.position < b.position) return -1;
          if (b.position < a.position) return 1;
          return 0;
        });
      },

      set(value) {
        this.sortedProducts = value;

        let image_ids = value.map((item) => {
          return item.id;
        });

        let params = {
          product_ids: image_ids,
        };

        this.$store.dispatch(SORT_PRODUCT, params).then(() => {
          this.$emit("change");
        });
      },
    },
  },

  mounted() {
    this.sortedProducts = this.products;
  },

  watch: {
    products(newValue) {
      this.sortedProducts = newValue;
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.closeDialog();
      this.$emit("change");
    },

    closeDialog() {
      this.showEditForm = false;
      this.product = null;

      this.$emit("close");
    },

    confirmDelete(item) {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete(item);
    },

    delete(item) {
      this.$store.dispatch(DELETE_PRODUCT, item.id).then(() => {
        this.showSnack({
          text: "Immagine eliminata con successo.",
          color: "success",
          timeout: 3500,
        });

        this.changed();
        this.cleanupParams();
      });
    },

    openEditForm(item) {
      this.product = item;
      this.showEditForm = true;
    },
  },
};
</script>