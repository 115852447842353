<template>
    <v-row>
        <v-col class="d-flex justify-end">
            <v-dialog v-model="showForm" width="450" persistent>
                <v-card>
                    <v-card-title class="text-h5 primary" v-if="showNewForm">
                        Nuova card
                    </v-card-title>
                    <v-card-title class="text-h5 primary" v-if="showEditForm">
                        Modifica card
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <img
                                    :src="img_front"
                                    style="width: 50%"
                                    v-if="img_front != null" />
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    accept="image/*"
                                    label="Immagine frontale"
                                    v-model="form.img_front"
                                    @change="(file) => onFileChange(file, 'img_front')"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <img
                                    :src="img_back"
                                    style="width: 50%"
                                    v-if="img_back != null" />
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    accept="image/*"
                                    label="Immagine posteriore"
                                    v-model="form.img_back"
                                    @change="(file) => onFileChange(file, 'img_back')"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <img
                                    :src="img_back_mobile"
                                    style="width: 50%"
                                    v-if="img_back_mobile != null" />
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    accept="image/*"
                                    label="Immagine posteriore (mobile)"
                                    v-model="form.image"
                                    @change="(file) => onFileChange(file, 'img_back_mobile')"
                                ></v-file-input>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    label="Titolo"
                                    v-model="form.title"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    label="Descrizione"
                                    v-model="form.description"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    label="Quotazione"
                                    v-model="form.quote"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    label="Didascalia"
                                    v-model="form.caption"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="secondary"
                            dark
                            class="mr-2 submit"
                            @click="closeDialog">
                            Annulla
                        </v-btn>
                        <v-btn color="primary" @click="submit"> Salva </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
import {
    CREATE_CARD_ITEM,
    UPDATE_CARD_ITEM,
} from "@/core/services/store/card_items.module";

export default {
    name: "CardItemForm",

    props: {
        cardItem: {
            type: Object,
            default: function () {
                return {};
            },
        },

        cardBlockId: {
            type: String,
        },

        showNewForm: {
            type: Boolean,
            default: false,
        },

        showEditForm: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            form: {
                title: null,
                description: null,
                quote: null,
                caption: null,
                img_front: null,
                img_back: null,
                img_back_mobile: null,
            },

            img_front: null,
            img_back: null,
            img_back_mobile: null,
            isFormValid: false,
        };
    },

    watch: {
        showEditForm(newValue) {
            if (newValue) {
                this.initForm();
            }
        },

        showNewForm(newValue) {
            if (newValue) {
                this.initForm();
            }
        },
    },

    computed: {
        showForm: {
            get() {
                return this.showNewForm || this.showEditForm;
            },
            set() {},
        },
    },

    methods: {
        ...mapActions("snackbar", ["showSnack"]),

        closeDialog() {
            this.$emit("close");
        },

        onFileChange(file, field) {
            if (file != null) {
                this.$set(this, field, URL.createObjectURL(file));
            } else {
                this.$set(this, field, null);
            }

            this.form[field] = file;
        },

        initForm() {
            if (this.showNewForm) {
                this.img_front = null;
                this.img_back = null;
                this.img_back_mobile = null;
            }

            if (this.cardItem != null) {
                this.form.title = this.cardItem.title;
                this.form.description = this.cardItem.description;
                this.form.quote = this.cardItem.quote;
                this.form.caption = this.cardItem.caption;
                this.form.img_front = null;
                this.form.img_back = null;
                this.form.img_back_mobile = null;
                this.img_front = this.cardItem.img_front_url;
                this.img_back = this.cardItem.img_back_url;
                this.img_back_mobile = this.cardItem.img_back_mobile_url;
            } else {
                this.form.title = null;
                this.form.description = null;
                this.form.quote = null;
                this.form.caption = null;
                this.form.img_front = null;
                this.form.img_back = null;
                this.form.img_back_mobile = null;
                this.img_front = null;
                this.img_back = null;
                this.img_back_mobile = null;
            }
        },

        submit() {
            this.validateForm();

            if (this.isFormValid) {
                let params = {
                    title: this.form.title,
                    description: this.form.description,
                    quote: this.form.quote,
                    caption: this.form.caption,
                };
                if (this.form.img_front != null) {
                    params["img_front"] = this.form.img_front;
                }
                if (this.form.img_back != null) {
                    params["img_back"] = this.form.img_back;
                }
                if (this.form.img_back_mobile != null) {
                    params["img_back_mobile"] = this.form.img_back_mobile;
                }

                if (this.showEditForm) {
                    /// UPDATE
                    let formData = this.jsonToFormData({ card: params });

                    this.$store
                        .dispatch(UPDATE_CARD_ITEM, {
                            id: this.cardItem.id,
                            params: formData,
                        })
                        .then(() => {
                            this.closeDialog();

                            this.showSnack({
                                text: "Card aggiornata con successo.",
                                color: "success",
                                timeout: 3500,
                            });

                            this.$emit("change");
                        });
                } else if (this.showNewForm) {
                    /// CREATE
                    params["card_block_id"] = this.cardBlockId;

                    let formData = this.jsonToFormData({ card: params });

                    this.$store
                        .dispatch(CREATE_CARD_ITEM, formData)
                        .then(() => {
                            this.closeDialog();

                            this.showSnack({
                                text: "Card creata con successo.",
                                color: "success",
                                timeout: 3500,
                            });
                            this.$emit("change");
                        });
                }
            }
        },

        validateForm() {
            this.isFormValid = this.img_front != null
                && this.img_back != null
                && this.img_back_mobile != null;
        },
    },
};
</script>