<template>
    <v-row>
        <v-col class="d-flex justify-end">
            <v-dialog v-model="showForm" width="450" persistent>
                <v-card>
                    <v-card-title class="text-h5 primary" v-if="showNewForm">
                        Nuovo asset
                    </v-card-title>
                    <v-card-title class="text-h5 primary" v-if="showEditForm">
                        Modifica asset
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                Come lo facciamo vedere?
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    label="Attachment"
                                    v-model="form.attachment"
                                    @change="(file) => onFileChange(file, 'attachment')"
                                ></v-file-input>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    label="Testo link"
                                    v-model="form.link_text"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="secondary"
                            dark
                            class="mr-2 submit"
                            @click="closeDialog">
                            Annulla
                        </v-btn>
                        <v-btn color="primary" @click="submit"> Salva </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
import {
    CREATE_ASSET_ITEM,
    UPDATE_ASSET_ITEM,
} from "@/core/services/store/asset_items.module";

export default {
    name: "AssetItemForm",

    props: {
        assetItem: {
            type: Object,
            default: function () {
                return {};
            },
        },

        assetBlockId: {
            type: String,
        },

        showNewForm: {
            type: Boolean,
            default: false,
        },

        showEditForm: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            form: {
                link_text: null,
                attachment: null,
            },

            attachment: null,
            isFormValid: false,
        };
    },

    watch: {
        showEditForm(newValue) {
            if (newValue) {
                this.initForm();
            }
        },

        showNewForm(newValue) {
            if (newValue) {
                this.initForm();
            }
        },
    },

    computed: {
        showForm: {
            get() {
                return this.showNewForm || this.showEditForm;
            },
            set() {},
        },
    },

    methods: {
        ...mapActions("snackbar", ["showSnack"]),

        closeDialog() {
            this.$emit("close");
        },

        onFileChange(file, field) {
            if (file != null) {
                this.$set(this, field, URL.createObjectURL(file));
            } else {
                this.$set(this, field, null);
            }

            this.form[field] = file;
        },

        initForm() {
            if (this.showNewForm) {
                this.attachment = null;
            }

            if (this.assetItem != null) {
                this.form.link_text = this.assetItem.link_text;
                this.form.attachment = null;
                this.attachment = this.assetItem.attachment;
            } else {
                this.form.link_text = null;
                this.form.attachment = null;
                this.attachment = null;
            }
        },

        submit() {
            this.validateForm();

            if (this.isFormValid) {
                let params = {
                    link_text: this.form.link_text,
                };
                if (this.form.attachment != null) {
                    params["attachment"] = this.form.attachment;
                }

                if (this.showEditForm) {
                    /// UPDATE
                    let formData = this.jsonToFormData({ asset: params });

                    this.$store
                        .dispatch(UPDATE_ASSET_ITEM, {
                            id: this.assetItem.id,
                            params: formData,
                        })
                        .then(() => {
                            this.closeDialog();

                            this.showSnack({
                                text: "Asset aggiornato con successo.",
                                color: "success",
                                timeout: 3500,
                            });

                            this.$emit("change");
                        });
                } else if (this.showNewForm) {
                    /// CREATE
                    params["asset_block_id"] = this.assetBlockId;

                    let formData = this.jsonToFormData({ asset: params });

                    this.$store
                        .dispatch(CREATE_ASSET_ITEM, formData)
                        .then(() => {
                            this.closeDialog();

                            this.showSnack({
                                text: "Asset creato con successo.",
                                color: "success",
                                timeout: 3500,
                            });
                            
                            this.$emit("change");
                        });
                }
            }
        },

        validateForm() {
            this.isFormValid = this.attachment != null;
        },
    },
};
</script>