<template>
  <div data-app v-if="chapter != null">
    <v-col md="9" offset-xl="2" xl="6">
      <v-row>
        <v-col cols="12">
          <h1 class="mt-5 mb-5">Capitolo: {{ chapterName }}</h1>
        </v-col>
      </v-row>

      <v-divider class="mt-5 mb-5"></v-divider>

      <v-row class="mb-5" v-if="creatable">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="primary" dark class="mr-4" @click="newColorBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Colore
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="openGalleryDialog">
            <i class="flaticon2-add-1 mr-2"></i>
            Galleria
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="newProductBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Prodotto
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="newRangeBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Range
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="newSwiperBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Slider
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="newTextBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Testo
          </v-btn>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="primary" dark class="mr-4" @click="newCardBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Card
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="newAssetBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Asset
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="newImageBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Immagine
          </v-btn>
          <v-btn color="primary" dark class="mr-4" @click="newPowerBrandBlock">
            <i class="flaticon2-add-1 mr-2"></i>
            Power Brand
          </v-btn>
        </v-col>
      </v-row>

      <div
        v-for="(block, index) in chapter.blocks"
        :key="block.id + '_' + Date().toString()"
        :data-index="index"
      >
        <TextBlock
          v-if="block.block_type == 'TextBlock'"
          :textBlock="block"
          label=""
          :deletable="deletable"
          @change="fetchChapter"
        />

        <GalleryBlock
          v-if="block.block_type == 'GalleryBlock'"
          :chapterId="chapterId"
          :galleryBlock="block"
          :deletable="deletable"
          @close="closeDialog"
          @change="fetchChapter"
        />

        <SwiperBlock
          v-if="block.block_type == 'SwiperBlock'"
          :chapterId="chapterId"
          :swiperBlock="block"
          :deletable="deletable"
          @close="closeDialog"
          @change="fetchChapter"
        />

        <ProductBlock
          v-if="block.block_type == 'ProductBlock'"
          :chapterId="chapterId"
          :productBlock="block"
          :deletable="deletable"
          @close="closeDialog"
          @change="fetchChapter"
        />

        <ColorBlock
          v-if="block.block_type == 'ColorBlock'"
          :chapterId="chapterId"
          :colorBlock="block"
          :deletable="deletable"
          @change="fetchChapter"
          @close="closeDialog"
        />

        <RangeBlock
          v-if="block.block_type == 'RangeBlock'"
          :chapterId="chapterId"
          :rangeBlock="block"
          :deletable="deletable"
          @change="fetchChapter"
          @close="closeDialog"
        />

        <CardBlock
          v-if="block.block_type == 'CardBlock'"
          :chapterId="chapterId"
          :cardBlock="block"
          :deletable="deletable"
          @change="fetchChapter"
          @close="closeDialog"
        />

        <AssetBlock
          v-if="block.block_type == 'AssetBlock'"
          :chapterId="chapterId"
          :assetBlock="block"
          :deletable="deletable"
          @change="fetchChapter"
          @close="closeDialog"
        />

        <ImageBlock
          v-if="block.block_type == 'ImageBlock'"
          :imageBlock="block"
          :deletable="deletable"
          @change="fetchChapter"
          @close="closeDialog"
        />

        <PowerBrandBlock
          v-if="block.block_type == 'PowerBrandBlock'"
          :block="block"
          :deletable="deletable"
          @change="fetchChapter"
          @close="closeDialog"
        />
      </div>

      <!-- Form new GalleryBlock -->
      <GalleryBlockForm
        :chapterId="chapterId"
        :showNewForm="showNewGalleryForm"
        :deletable="deletable"
        @change="fetchChapter"
        @close="closeDialog"
      />

      <!-- Form new ImageBlock -->
      <ImageBlockForm
        :chapterId="chapterId"
        :showNewForm="showNewImageForm"
        :deletable="deletable"
        @change="fetchChapter"
        @close="closeDialog"
      />
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  CHAPTER_DETAIL,
  SET_CREATABLE,
  SET_DELETABLE,
} from "@/core/services/store/chapters.module";
import { CREATE_TEXT_BLOCK } from "@/core/services/store/text_blocks.module";
import { CREATE_COLOR_BLOCK } from "@/core/services/store/color_blocks.module";
import { CREATE_SWIPER_BLOCK } from "@/core/services/store/swiper_blocks.module";
import { CREATE_PRODUCT_BLOCK } from "@/core/services/store/product_blocks.module";
import { CREATE_RANGE_BLOCK } from "@/core/services/store/range_blocks.module";
import { CREATE_CARD_BLOCK } from "@/core/services/store/card_blocks.module";
import { CREATE_ASSET_BLOCK } from "@/core/services/store/asset_blocks.module";
import { CREATE_POWER_BRAND_BLOCK } from "@/core/services/store/power_brand_blocks.module";
import ColorBlock from "@/view/components/Block/ColorBlock.vue";
import GalleryBlock from "@/view/components/Block/GalleryBlock.vue";
import GalleryBlockForm from "@/view/components/Block/Forms/GalleryBlockForm.vue";
import ProductBlock from "@/view/components/Block/ProductBlock.vue";
import RangeBlock from "@/view/components/Block/RangeBlock.vue";
import SwiperBlock from "@/view/components/Block/SwiperBlock.vue";
import TextBlock from "@/view/components/Block/TextBlock.vue";
import CardBlock from "@/view/components/Block/CardBlock.vue";
import AssetBlock from "@/view/components/Block/AssetBlock.vue";
import ImageBlock from "@/view/components/Block/ImageBlock.vue";
import ImageBlockForm from "@/view/components/Block/Forms/ImageBlockForm.vue";
import PowerBrandBlock from "@/view/components/Block/PowerBrandBlock.vue";

export default {
  components: {
    ColorBlock,
    GalleryBlock,
    GalleryBlockForm,
    SwiperBlock,
    ProductBlock,
    RangeBlock,
    TextBlock,
    CardBlock,
    AssetBlock,
    ImageBlock,
    ImageBlockForm,
    PowerBrandBlock,
  },

  data() {
    return {
      chapter: {},
      chapterId: this.$route.params.id,
      chapterName: this.$route.params.name || "",

      showNewGalleryForm: false,
      showNewColorForm: false,
      showNewImageForm: false,
    };
  },

  mounted() {
    if (this.chapterId != null) {
      this.fetchChapter();
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit(SET_CREATABLE, true);
    this.$store.commit(SET_DELETABLE, true);
    next();
  },

  computed: {
    ...mapState({
      creatable: (state) => state.chapters.creatable,
      deletable: (state) => state.chapters.deletable,
    }),
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    fetchChapter() {
      this.closeDialog();

      this.$store
        .dispatch(CHAPTER_DETAIL, {
          id: this.chapterId,
        })
        .then((data) => {
          this.chapter = data.chapter;
        });
    },

    /// Gallery
    openGalleryDialog() {
      this.showNewGalleryForm = true;
    },

    closeDialog() {
      this.showNewGalleryForm = false;
      this.showNewColorForm = false;
      this.showNewImageForm = false;
    },

    /// Text
    newTextBlock() {
      this.$store
        .dispatch(CREATE_TEXT_BLOCK, {
          block: {
            blockable_type: "TextBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Blocco di testo creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },

    /// Color
    newColorBlock() {
      this.$store
        .dispatch(CREATE_COLOR_BLOCK, {
          block: {
            blockable_type: "ColorBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Blocco colori creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },

    /// Swiper
    newSwiperBlock() {
      this.$store
        .dispatch(CREATE_SWIPER_BLOCK, {
          block: {
            blockable_type: "SwiperBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Slider creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },

    /// Product
    newProductBlock() {
      this.$store
        .dispatch(CREATE_PRODUCT_BLOCK, {
          block: {
            blockable_type: "ProductBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Blocco prodotti creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },

    /// Range
    newRangeBlock() {
      this.$store
        .dispatch(CREATE_RANGE_BLOCK, {
          block: {
            blockable_type: "RangeBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Range creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },

    /// Card
    newCardBlock() {
      this.$store
        .dispatch(CREATE_CARD_BLOCK, {
          block: {
            blockable_type: "CardBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Card creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },

    /// Asset
    newAssetBlock() {
      this.$store
        .dispatch(CREATE_ASSET_BLOCK, {
          block: {
            blockable_type: "AssetBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Asset creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },

    newImageBlock() {
      this.showNewImageForm = true;
    },

    newPowerBrandBlock() {
      this.$store
        .dispatch(CREATE_POWER_BRAND_BLOCK, {
          block: {
            blockable_type: "PowerBrandBlock",
            chapter_id: this.chapterId,
          },
        })
        .then(() => {
          this.showSnack({
            text: "Power Brand creato con successo.",
            color: "success",
            timeout: 3500,
          });

          window.scrollTo(0, document.body.scrollHeight);

          this.fetchChapter();
        });
    },
  },
};
</script>
