<template>
    <div>
        <draggable
            v-model="sortableItems"
            class="image-sorter-container row d-flex justify-start"
            :group="cardBlockId"
            @start="drag = true"
            @end="drag = false">
            <v-col
                class="gallery-image-container"
                v-for="item in sortedItems"
                :cols="columns"
                :key="item.id">

                <!-- Header -->
                <v-btn-toggle
                    dense
                    style="background-color: transparent"
                    class="d-flex justify-end">
                    <v-btn class="edit-btn" @click="openEditForm(item)">
                        <i class="flaticon2-edit"></i>
                    </v-btn>
                    <v-btn class="delete-btn" @click="confirmDelete(item)" 
                        v-if="deletable">
                        <i class="flaticon2-rubbish-bin"></i>
                    </v-btn>
                </v-btn-toggle>

                <!-- Dettagli immagine -->
                <v-carousel
                    hide-delimiters
                    height="300">
                    <v-carousel-item
                        v-for="(slide, i) in slides(item)"
                        :key="i"
                        :src="slide">
                        <v-container
                            fill-height
                            fluid
                            pa-0
                            ma-0>
                            <v-layout
                                fill-height
                                align-end>
                                <v-flex xs12>
                                    <v-card color="red" class="pa-2">
                                        <span class="headline white--text" 
                                            v-text="(i == 0 ? 'Immagine frontale' : (i === 1 ? 'Immagine posteriore' : 'Immagine posteriore mobile'))"></span>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-carousel-item>
                </v-carousel>

                <div class="mb-5"></div>

                <div v-if="item.title != null && item.title != ''">
                    <label class="mr-2">Titolo</label><span>{{ item.title }}</span>
                </div>
                <div v-if="item.description != null && item.description != ''">
                    <label class="mr-2">Descrizione</label
                    ><span>{{ item.description }}</span>
                </div>
                <div v-if="item.quote != null && item.quote != ''">
                    <label class="mr-2">Quotazione</label
                    ><span>{{ item.quote }}</span>
                </div>
                <div v-if="item.caption != null && item.caption != ''">
                    <label class="mr-2">Didascalia</label
                    ><span>{{ item.caption }}</span>
                </div>
            </v-col>
        </draggable>

        <!-- Form edit CardItem -->
        <CardItemForm
            :cardItem="cardItem"
            :showEditForm="showEditForm"
            @change="changed"
            @close="closeDialog" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  DELETE_CARD_ITEM,
  SORT_CARD_ITEMS,
} from "@/core/services/store/card_items.module";
import CardItemForm from "@/view/components/Block/Forms/CardItemForm.vue";
import draggable from "vuedraggable";

export default {
    name: "CardItem",

    components: {
        CardItemForm,
        draggable,
    },

    props: {
        cards: {
            type: Array,
            default: function () {
            return [];
            },
        },

        columns: {
            type: Number,
            require: true,
            default: 6,
        },

        cardBlockId: {
            type: String,
        },

        deletable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            cardItem: null,
            showEditForm: false,
            drag: false,
            sortedItems: [],
        };
    },

    computed: {
        sortableItems: {
            get() {
                return (this.cards || []).sort((a, b) => {
                    if (a.position < b.position) return -1;
                    if (b.position < a.position) return 1;
                    return 0;
                });
            },

            set(value) {
                this.sortedItems = value;

                let card_ids = value.map((item) => {
                    return item.id;
                });

                let params = {
                    card_ids: card_ids,
                };

                this.$store.dispatch(SORT_CARD_ITEMS, params).then(() => {
                    this.$emit("change");
                });
            },
        },
    },

    mounted() {
        this.sortedItems = this.cards;
    },

    watch: {
        cards(newValue) {
            this.sortedItems = newValue;
        },
    },

    methods: {
        ...mapActions("snackbar", ["showSnack"]),

        changed() {
            this.closeDialog();
            this.$emit("change");
        },

        closeDialog() {
            this.showEditForm = false;
            this.cardItem = null;

            this.$emit("close");
        },

        confirmDelete(item) {
            confirm(
                "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
            ) && this.delete(item);
        },

        delete(item) {
            this.$store.dispatch(DELETE_CARD_ITEM, item.id).then(() => {
                this.showSnack({
                    text: "Card eliminata con successo.",
                    color: "success",
                    timeout: 3500,
                });

                this.changed();
                this.cleanupParams();
            });
        },

        openEditForm(item) {
            this.cardItem = item;
            this.showEditForm = true;
        },

        slides(item) {
            const arr = [];
            if (item.img_front_url) arr.push(item.img_front_url);
            if (item.img_back_url) arr.push(item.img_back_url);
            if (item.img_back_mobile_url) arr.push(item.img_back_mobile_url);
            return arr;
        },
    },
};
</script>