<template>
    <div class="block-row mb-5">
        <!-- Header -->
        <v-row class="d-flex justify-end">
            <v-col cols="6">
                <h4>Card</h4>
            </v-col>

            <v-spacer></v-spacer>
            
            <v-btn-toggle dense style="background-color: transparent">
                <v-btn class="new-btn" @click="openNewForm">
                    <i class="flaticon2-add-1"></i>
                </v-btn>
                <v-btn class="delete-btn" v-if="deletable" @click="confirmDelete">
                    <i class="flaticon2-rubbish-bin"></i>
                </v-btn>
            </v-btn-toggle>
        </v-row>

        <v-divider></v-divider>

        <!-- Cards -->
        <CardItem
            :cards="cardBlock.content.cards"
            :cardBlockId="cardBlock.content.id"
            @change="changed"
            @close="closeDialog" />

        <!-- Form edit CardItem -->
        <CardItemForm
            :cardBlockId="cardBlock.content.id"
            :showNewForm="showNewForm"
            @change="changed"
            @close="closeDialog" />
    </div>
</template>


<script>
import { mapActions } from "vuex";
import {
  DELETE_TEXT_BLOCK,
} from "@/core/services/store/text_blocks.module";
import CardItem from '@/view/components/Block/CardItem.vue'
import CardItemForm from "@/view/components/Block/Forms/CardItemForm.vue";

export default {
    components: {
        CardItem,
        CardItemForm,
    },

    props: {
        cardBlock: {
            type: Object,
            default: function () {
                return {};
            },
        },

        deletable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            showNewForm: false,
        };
    },

    methods: {
        ...mapActions("snackbar", ["showSnack"]),

        changed() {
            this.closeDialog();
            this.$emit("change");
        },

        closeDialog() {
            this.showNewForm = false;

            this.$emit("close");
        },

        confirmDelete() {
            confirm(
                "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
            ) && this.delete();
        },

        delete() {
            this.$store.dispatch(DELETE_TEXT_BLOCK, this.cardBlock.id).then(() => {
                this.showSnack({
                    text: "Blocco card eliminato con successo.",
                    color: "success",
                    timeout: 3500,
                });

                this.$emit("change");
            });
        },

        openNewForm() {
            this.showNewForm = true;
        }
    },
};
</script>
