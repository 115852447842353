<template>
  <div>
    <draggable
      v-model="sortableItems"
      class="color-sorter-container row d-flex justify-start"
      :group="colorBlockId"
      @start="drag = true"
      @end="drag = false"
    >
      <v-col
        class="gallery-color-container"
        v-for="item in sortedColors"
        cols="6"
        :key="item.id"
      >
        <div :style="colorBackgroundStyle(item)">
          <!-- Header -->
          <v-btn-toggle
            dense
            style="background-color: transparent"
            class="d-flex justify-end"
          >
            <v-btn class="edit-btn" @click="openEditForm(item)"
              ><i class="flaticon2-edit"></i
            ></v-btn>
            <v-btn class="delete-btn" @click="confirmDelete(item)"
              v-if="deletable">
              <i class="flaticon2-rubbish-bin"></i>
            </v-btn>
          </v-btn-toggle>
        </div>

        <div class="mb-5"></div>

        <!-- <div v-if="item.name != null && item.name != ''">
          <label class="mr-2"
            ><span>{{ item.name }}</span></label
          >
        </div> -->
        <div v-if="item.cmyk != [0, 0, 0, 0] && item.rgb != [0, 0, 0]">
          <label class="mr-2">CMYK:</label
          ><span>{{ item.cmyk.join(", ") }}</span>
        </div>
        <div v-if="item.cmyk != [0, 0, 0, 0] && item.rgb != [0, 0, 0]">
          <label class="mr-2">RGB:</label>
          <span>{{ item.rgb.join(", ") }}</span>
        </div>
        <div v-if="item.hex != null && item.hex != ''">
          <label class="mr-2">HEX:</label>
          <span>{{ item.hex }}</span>
        </div>
        <div v-if="item.ral != null && item.ral != ''">
          <label class="mr-2">RAL:</label>
          <span>{{ item.ral }}</span>
        </div>
      </v-col>
    </draggable>

    <ColorForm
      :color="color"
      :showEditForm="showEditForm"
      @change="changed"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { DELETE_COLOR, SORT_COLOR } from "@/core/services/store/colors.module";
import ColorForm from "@/view/components/Block/Forms/ColorForm.vue";
import draggable from "vuedraggable";

export default {
  name: "Gallerycolor",

  components: {
    ColorForm,
    draggable,
  },

  props: {
    colors: {
      type: Array,
      default: function () {
        return [];
      },
    },

    colorBlockId: {
      type: String,
    },
    
    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showEditForm: false,
      color: null,
      drag: false,
      sortedColors: [],
    };
  },

  computed: {
    sortableItems: {
      get() {
        return (this.colors || []).sort((a, b) => {
          if (a.position < b.position) return -1;
          if (b.position < a.position) return 1;
          return 0;
        });
      },

      set(value) {
        this.sortedColors = value;

        let colorIds = value.map((item) => {
          return item.id;
        });

        let params = {
          color_ids: colorIds,
        };

        this.$store.dispatch(SORT_COLOR, params).then(() => {
          this.$emit("change");
        });
      },
    },
  },

  mounted() {
    this.sortedColors = this.colors;
  },

  watch: {
    colors(newValue) {
      this.sortedColors = newValue;
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.closeDialog();
      this.$emit("change");
    },

    closeDialog() {
      this.showEditForm = false;
      this.color = null;

      this.$emit("close");
    },

    colorBackgroundStyle(item) {
      var style = "height: 200px;background-color: ";

      if (item.hex != null) {
        return style + item.hex;
      }
      return style + "#fff";
    },

    confirmDelete(item) {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete(item);
    },

    delete(item) {
      this.$store.dispatch(DELETE_COLOR, item.id).then(() => {
        this.showSnack({
          text: "Colore eliminato con successo.",
          color: "success",
          timeout: 3500,
        });

        this.changed();
        this.cleanupParams();
      });
    },

    openEditForm(item) {
      this.color = item;
      this.showEditForm = true;
    },
  },
};
</script>