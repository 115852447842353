<template>
  <div>
    <div class="block-row mb-5">
      <!-- Header -->
      <v-row class="d-flex justify-end">
        <v-col cols="6">
          <h4>Prodotti</h4>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn-toggle dense style="background-color: transparent">
          <v-btn class="new-btn" @click="openNewImageForm"
            ><i class="flaticon2-add-1"></i
          ></v-btn>

          <v-btn class="delete-btn" @click="confirmDelete"
            v-if="deletable">
            <i class="flaticon2-rubbish-bin"></i>
          </v-btn>
        </v-btn-toggle>
      </v-row>

      <v-divider></v-divider>

      <!-- Dettagli galleria immagini -->

      <!-- Immagini -->
      <Product
        :products="this.productBlock.content.products"
        :productBlockId="this.productBlock.content.id"
        @change="changed"
        @close="closeDialog"
      />

      <ProductForm
        :productBlockId="this.productBlock.content.id"
        :showNewForm="showNewProductForm"
        @change="changed"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { DELETE_PRODUCT_BLOCK } from "@/core/services/store/product_blocks.module";

import Product from "@/view/components/Block/Product.vue";
import ProductForm from "@/view/components/Block/Forms/ProductForm.vue";

export default {
  name: "ProductBlock",

  components: {
    Product,
    ProductForm,
  },

  props: {
    productBlock: {
      type: Object,
      default: function () {
        return {};
      },
    },
    chapterId: {
      type: String,
    },
    
    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isFormValid: false,
      showEditForm: false,
      showNewProductForm: false,
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.closeDialog();
      this.$emit("change");
    },

    closeDialog() {
      this.showEditForm = false;
      this.showNewProductForm = false;

      this.$emit("close");
    },

    confirmDelete() {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete();
    },

    delete() {
      this.$store
        .dispatch(DELETE_PRODUCT_BLOCK, this.productBlock.id)
        .then(() => {
          this.showSnack({
            text: "Blocco prodotti eliminato con successo.",
            color: "success",
            timeout: 3500,
          });

          this.changed();
          this.cleanupParams();
        });
    },

    openNewImageForm() {
      this.showNewProductForm = true;
    },
  },
};
</script>