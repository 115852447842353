<template>
  <div>
    <draggable
      v-model="sortableItems"
      class="image-sorter-container row d-flex justify-start"
      :group="galleryBlockId"
      @start="drag = true"
      @end="drag = false"
    >
      <v-col
        class="gallery-image-container"
        v-for="item in sorteredImages"
        :cols="columns"
        :key="item.id"
      >
        <!-- Header -->
        <v-btn-toggle
          dense
          style="background-color: transparent"
          class="d-flex justify-end"
        >
          <v-btn class="edit-btn" @click="openEditForm(item)"
            ><i class="flaticon2-edit"></i
          ></v-btn>
          <v-btn class="delete-btn" @click="confirmDelete(item)"
            v-if="deletable">
            <i class="flaticon2-rubbish-bin"></i>
          </v-btn>
        </v-btn-toggle>

        <!-- Dettagli immagine -->
        <img
          :src="item.image_url_1_1"
          v-if="imageFormat == '1_1'"
          class="gallery-image"
        />
        <img
          :src="item.image_url_16_9"
          v-if="imageFormat == '16_9'"
          class="gallery-image"
        />
        <img
          :src="item.image_url"
          v-if="imageFormat != '1_1' && imageFormat != '16_9'"
          class="gallery-image"
        />

        <div class="mb-5"></div>
        <div v-if="item.title != null && item.title != ''">
          <label class="mr-2">Titolo</label><span>{{ item.title }}</span>
        </div>
        <div v-if="item.description != null && item.description != ''">
          <label class="mr-2">Descrizione</label
          ><span>{{ item.description }}</span>
        </div>
        <div v-if="item.caption_top != null && item.caption_top != ''">
          <label class="mr-2">Didascalia superiore</label
          ><span>{{ item.caption_top }}</span>
        </div>
        <div v-if="item.caption_bottom != null && item.caption_bottom != ''">
          <label class="mr-2">Didascalia inferiore</label
          ><span>{{ item.caption_bottom }}</span>
        </div>
        <div v-if="item.link_text != null && item.link_text != ''">
          <label class="mr-2">Button</label>
          <a :href="item.link" target="_blank">{{ item.link_text }}</a>
        </div>

        <div v-if="item.incorrect != null && item.incorrect">
          <label style="color: #f35050" class="mr-2">Incorretto</label>
        </div>
      </v-col>
    </draggable>

    <!-- Form edit GalleryImage -->
    <GalleryImageForm
      :galleryImage="galleryImage"
      :showEditForm="showEditForm"
      @change="changed"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  DELETE_GALLERY_IMAGE,
  SORT_GALLERY_IMAGE,
} from "@/core/services/store/gallery_images.module";
import GalleryImageForm from "@/view/components/Block/Forms/GalleryImageForm.vue";
import draggable from "vuedraggable";

export default {
  name: "GalleryImage",

  components: {
    GalleryImageForm,
    draggable,
  },

  props: {
    images: {
      type: Array,
      default: function () {
        return [];
      },
    },

    columns: {
      type: Number,
      require: true,
    },

    imageFormat: {
      type: String,
      require: true,
    },

    galleryBlockId: {
      type: String,
    },

    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      galleryImage: null,
      showEditForm: false,
      drag: false,
      sorteredImages: [],
    };
  },

  computed: {
    sortableItems: {
      get() {
        return (this.images || []).sort((a, b) => {
          if (a.position < b.position) return -1;
          if (b.position < a.position) return 1;
          return 0;
        });
      },

      set(value) {
        this.sorteredImages = value;

        let image_ids = value.map((item) => {
          return item.id;
        });

        let params = {
          gallery_image_ids: image_ids,
        };

        this.$store.dispatch(SORT_GALLERY_IMAGE, params).then(() => {
          this.$emit("change");
        });
      },
    },
  },

  mounted() {
    this.sorteredImages = this.images;
  },

  watch: {
    images(newValue) {
      this.sorteredImages = newValue;
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.closeDialog();
      this.$emit("change");
    },

    closeDialog() {
      this.showEditForm = false;
      this.galleryImage = null;

      this.$emit("close");
    },

    confirmDelete(item) {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete(item);
    },

    delete(item) {
      this.$store.dispatch(DELETE_GALLERY_IMAGE, item.id).then(() => {
        this.showSnack({
          text: "Immagine eliminata con successo.",
          color: "success",
          timeout: 3500,
        });

        this.changed();
        this.cleanupParams();
      });
    },

    openEditForm(item) {
      this.galleryImage = item;
      this.showEditForm = true;
    },
  },
};
</script>