<template>
  <div class="block-row mb-5">
    <!-- Header -->
    <v-row class="d-flex justify-end">
      <v-col cols="6">
        <h4>Range</h4>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn-toggle dense style="background-color: transparent">
        <v-btn class="edit-btn" @click="openEditForm"
          ><i class="flaticon2-edit"></i
        ></v-btn>
        <v-btn class="delete-btn" v-if="deletable" @click="confirmDelete">
          <i class="flaticon2-rubbish-bin"></i>
        </v-btn>
      </v-btn-toggle>
    </v-row>

    <v-divider></v-divider>
    <v-row>
      <v-col cols="6">
        <img
          :src="rangeBlock.content.range.source_above_url"
          class="gallery-image"
          style="width: 100%"
        />
      </v-col>

      <v-col cols="6">
        <img
          :src="rangeBlock.content.range.source_below_url"
          class="gallery-image"
          style="width: 100%"
        />
      </v-col>

      <v-col cols="12">
        <div class="mb-5"></div>
        <div
          v-if="
            rangeBlock.content.range.caption != null &&
            rangeBlock.content.range.caption != ''
          "
        >
          <label class="mr-2">Didascalia</label>
          <span>{{ rangeBlock.content.range.caption }}</span>
        </div>

        <div>
          <label class="mr-2">Soglia:</label>
          <span>{{ rangeBlock.content.range.threshold || 0 }}</span>
        </div>
      </v-col>
    </v-row>

    <RangeForm
      :range="this.rangeBlock.content.range"
      :showEditForm="showEditForm"
      @change="changed"
      @close="closeDialog"
    />
  </div>
</template>


<script>
import { mapActions } from "vuex";
import RangeForm from "@/view/components/Block/Forms/RangeForm.vue";
import {
  // UPDATE_TEXT_BLOCK,
  DELETE_TEXT_BLOCK,
} from "@/core/services/store/text_blocks.module";

export default {
  components: {
    RangeForm,
  },

  props: {
    rangeBlock: {
      type: Object,
      default: function () {
        return {};
      },
    },
    
    deletable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showEditForm: false,
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    changed() {
      this.closeDialog();
      this.$emit("change");
    },

    closeDialog() {
      this.showEditForm = false;
      this.galleryImage = null;

      this.$emit("close");
    },

    confirmDelete() {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete();
    },

    delete() {
      this.$store.dispatch(DELETE_TEXT_BLOCK, this.rangeBlock.id).then(() => {
        this.showSnack({
          text: "Blocco range eliminato con successo.",
          color: "success",
          timeout: 3500,
        });

        this.$emit("change");
      });
    },

    openEditForm() {
      this.showEditForm = true;
    },
  },
};
</script>
